import { GetsessionData } from "../../../src/logic/Axiosapicall";
import { decrypt } from "../../../src/logic/Encryption";
import Validlog from "./Validlog";
class Auth {
  constructor() {
    if (localStorage.getItem("UserLogedToken")) {
      this.userAuthenticated = true;
    } else {
      this.userAuthenticated = false;
    }
  }

  login() {
    if (localStorage.getItem("UserLogedToken")) {
      this.userAuthenticated = true;
      return this.userAuthenticated;
    } else {
      this.userAuthenticated = false;
      return this.userAuthenticated;
    }
  }

  logout(cd) {
    localStorage.removeItem("UserLogedToken");
    localStorage.removeItem("UserTokenName");
    if (localStorage.getItem("UserLogedToken")) {
      this.userAuthenticated = true;
      return this.userAuthenticated;
    } else {
      this.userAuthenticated = false;
      return this.userAuthenticated;
    }
  }

  isAuthenticated() {
    if (localStorage.getItem("UserLogedToken")) {
      Validlog.isChecktokensessonserver();
      if (localStorage.getItem("UserLogedToken")) {
        this.userAuthenticated = true;
        return this.userAuthenticated;
      } else {
        this.userAuthenticated = false;
        return this.userAuthenticated;
      }
    } else {
      this.userAuthenticated = false;
      return this.userAuthenticated;
    }
  }

  isAuthenticatedShiping() {
    if (sessionStorage.getItem("shippingData")) {
      this.userAuthenticated = true;
      return this.userAuthenticated;
    } else {
      this.userAuthenticated = false;
      return this.userAuthenticated;
    }
  }

  isAuthenticatedorder() {
    if (sessionStorage.getItem("orderdataData")) {
      this.userAuthenticated = true;
      return this.userAuthenticated;
    } else {
      this.userAuthenticated = false;
      return this.userAuthenticated;
    }
  }

  isAuthenticatedordersuccess() {
    if (sessionStorage.getItem("orderSuccess")) {
      this.userAuthenticated = true;
      return this.userAuthenticated;
    } else {
      this.userAuthenticated = false;
      return this.userAuthenticated;
    }
  }

  isAuthenticatedordersuccessdata() {
    if (sessionStorage.getItem("orderSuccess")) {
      return sessionStorage.getItem("orderSuccess");
    } else {
      this.userAuthenticated = false;
      return this.userAuthenticated;
    }
  }

  continueshopping() {
    if (sessionStorage.getItem("orderSuccess")) {
      sessionStorage.removeItem("orderSuccess");
      if (sessionStorage.getItem("orderSuccess")) {
        this.userAuthenticated = true;
        return this.userAuthenticated;
      } else {
        this.userAuthenticated = false;
        return this.userAuthenticated;
      }
    } else {
      this.userAuthenticated = false;
      return this.userAuthenticated;
    }
  }

  getSessionToken() {
    if (localStorage.getItem("UserLogedToken")) {
      return decrypt(localStorage.getItem("UserLogedToken"));
    } else {
      return false;
    }
  }

  getSessionData() {
    if (localStorage.getItem("UserLogedToken")) {
      if (localStorage.getItem("UserTokenName")) {
        return localStorage.getItem("UserTokenName");
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getSessionDataold() {
    if (localStorage.getItem("UserLogedToken")) {
      let token = localStorage.getItem("UserLogedToken");
      return GetsessionData(token).then(response => {
        if (response.status === 200 && response.statusText === "OK") {
          const dataApi = response.data;
          if (dataApi.status === "000" && dataApi.message === "SUCCESS") {
            const data = dataApi.result;
            return data;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    } else {
      return false;
    }
  }
}

export default new Auth();
