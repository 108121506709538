import React, { Suspense, lazy } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";
import {PrivateRoute} from "./PrivateRoute";
const AsyncApp = lazy(() => import("./App"));
const AsyncAppsecond = lazy(() => import("./Appsecond"));
const AsyncAppuser = lazy(() => import("./Appuser"));
require("dotenv").config();
const Appdom = () => {
  return (
    <>
      <Suspense fallback={<div> Loding..... </div>}>
        <BrowserRouter>
          <Switch>
            <PrivateRoute path="/buy" component={AsyncAppsecond} />
            <PrivateRoute path="/myacount" component={AsyncAppuser} />
            <Route component={AsyncApp} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default Appdom;
