import React from "react";
import auth from "./web/auth/auth";
import { urlsprit } from "./logic/Syntaxlogic";
import { checkforgot } from "./logic/Axiosapicall";
import { Route, Redirect } from "react-router-dom";
function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated() === true) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    />
  );
}

function Passwordforgot({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        let ur = urlsprit(props.location.pathname);
        checkforgot(ur);
        if (auth.isAuthenticated() !== true) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    />
  );
}

function ShippingPrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticatedShiping() === true) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{ pathname: "/checkout", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
}

function OrderPrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticatedorder() === true) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{ pathname: "/checkout", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
}

function withoutlogin({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticatedorder() === false) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    />
  );
}

function OrderSuccessPrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticatedordersuccess() === true) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{ pathname: "/checkout", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
}

export {
  PrivateRoute,
  ShippingPrivateRoute,
  OrderPrivateRoute,
  OrderSuccessPrivateRoute,
  Passwordforgot
};
