var jwt = require("jsonwebtoken");
require("dotenv").config();
class Authjwt {
  constructor() {
    this.userAuthenticated = true;
  }

  Datagenrate(data) {
    if (data) {
      let token = jwt.sign(data, process.env.REACT_APP_jst, {
        expiresIn: 4 * 60 * 1
      });
      if (token) {
        return token;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  Datadecode(token) {
    if (token) {
      try {
        var decoded = jwt.verify(token, process.env.REACT_APP_jst);
        return decoded;
      } catch (err) {
        return err;
      }
    } else {
      return false;
    }
  }
}
export default new Authjwt();
