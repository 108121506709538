
function ReplaceSpace(name) {
  if (name) {
    return name.split(" ").join("-");
  }
}

function chnagestring(name) {
  if (name) {
    return name.split("-").join(" ");
  } else {
    return "";
  }
}

function urlsprit(name) {
  if (name) {
    let sprit = name.split("/");
    let lentgh = sprit.length;
    if(lentgh === 3){
      return sprit[2];
    }else{
      return false;
    }
  
  } else {
    return false;
  }
}

function CreateURL(array) {
  return "/" + array.join("/");
}

function currentyear() {
  const current = new Date().getFullYear();
  return current;
}

function Dateformate(date) {
  if (date) {
    const datef = new Intl.DateTimeFormat("en-GB", {
      month: "long",
      day: "2-digit",
      year: "numeric"
    }).format(new Date(date));
    return datef;
  }
}

export {
  ReplaceSpace,
  CreateURL,
  chnagestring,
  currentyear,
  Dateformate,
  urlsprit
};
