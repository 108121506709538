import authjt from "../../src/web/auth/authjwt";
var crypto = require("crypto");
require("dotenv").config();
var ptr = process.env;
const orderkey = ptr.REACT_APP_orKey;
const ivkey = ptr.REACT_APP_ivKey;
const uk = ptr.REACT_APP_orTpyycp;
function encrypt(text) {
  if (text) {
    var cipher = crypto.createCipher(
      ptr.REACT_APP_orTpecp,
      ptr.REACT_APP_encKey
    );
    var crypted = cipher.update(text, "utf8", "hex");
    crypted += cipher.final("hex");
    return crypted;
  }
}

function decrypt(text) {
  if (text) {
    var decipher = crypto.createDecipher(
      ptr.REACT_APP_orTpecp,
      ptr.REACT_APP_encKey
    );
    var dec = decipher.update(text, "hex", "utf8");
    dec += decipher.final("utf8");
    return dec;
  }
}

function arrayencrypt(array) {
  if (array) {
    const strcnvt = JSON.stringify(array);
    const data = encrypt(strcnvt);
    return data;
  }
}

function stringencrypt(string) {
  if (string) {
    const encryptdata = decrypt(string);
    const data = JSON.parse(encryptdata);
    return data;
  }
}

function orderencrypt(array) {
  if (array) {
    const datatext = JSON.stringify(array);
    var key = orderkey;
    var iv = ivkey;
    var cipher = crypto.createCipheriv(uk, key, iv);
   
    var text = datatext;
    var encrypted = cipher.update(text, "utf8", "binary");
    encrypted += cipher.final("binary");
    var hexVal = new Buffer(encrypted, "binary");
    var newEncrypted = hexVal.toString("hex");
    return newEncrypted;
  }
}

function decryptdataresponse(string) {
  if (string) {
    var decipher = crypto.createDecipheriv(uk, orderkey, ivkey);
    var decryptedgg = decipher.update(string, "hex", "binary");
    decryptedgg += decipher.final("binary");
    const data = JSON.parse(decryptedgg);
    return data;
  }
}

function headerkeys() {
  let subhead = {
    XApiKey: ptr.REACT_APP_MIp,
    XApiSecretKey: ptr.REACT_APP_sKey
  };
  let request = authjt.Datagenrate(subhead);
  return request;
}

export {
  encrypt,
  decrypt,
  arrayencrypt,
  stringencrypt,
  orderencrypt,
  decryptdataresponse,
  headerkeys
};
