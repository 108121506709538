import React from 'react';
import ReactDOM from 'react-dom';
import Appdom from './Appdom';
import cartReducer from './reducer/cartReducer';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
require("dotenv").config();
const store = createStore(cartReducer,applyMiddleware(thunk));
ReactDOM.render(<Provider store={store}><Appdom /></Provider>, document.getElementById('root'));

