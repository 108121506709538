const iState = {
    cartdata :'',
}
const cartReducer = (state = iState,action) => {
    if(action.type === 'CANGE_CARTDATA'){
        return {
            cartdata : action.payload
        }
    }
    return state;
}

export default cartReducer;