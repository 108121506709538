import { GetsessionData } from "../../../src/logic/Axiosapicall";
import { encrypt} from "../../../src/logic/Encryption";
import auth from "./auth";
import authjt from "./authjwt";
class Validlog {

    isChecktokensessonserver() {
        GetsessionData().then(response => {
          if (response.status === 200 && response.statusText === "OK") {
            let dataApidatat = response.data.ResponseEncyptBody;
            let dataApi = authjt.Datadecode(dataApidatat);
            if (dataApi) {
              if (dataApi.status === "000" && dataApi.message === "SUCCESS") {
                let tkn = auth.getSessionToken();
                if (tkn) {
                  localStorage.setItem("UserLogedToken", encrypt(tkn));
                } else {
                  localStorage.removeItem("UserLogedToken");
                  localStorage.removeItem("UserTokenName");
                }
              } else {
                localStorage.removeItem("UserLogedToken");
                localStorage.removeItem("UserTokenName");
              }
            }
          }
        });
      }

}

export default new Validlog();